import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MaintenanceMessageComponent } from './component/maintenance-message.component';
import { MaintenanceMessageEffect } from './state/maintenance-message.effects';
import { MAINTENANCE_MESSAGE_FEATURE_STATE_NAME } from './state/action/maintenance-message.select';
import { MaintenanceMessageReducer } from './state/maintenance-message.reducer';
import { NcDateTimePickerModule } from 'nc-date-time-picker';

@NgModule({
	imports: [
		SharedModule,
		EffectsModule.forFeature([MaintenanceMessageEffect]),
		StoreModule.forFeature(MAINTENANCE_MESSAGE_FEATURE_STATE_NAME, MaintenanceMessageReducer),
		NcDateTimePickerModule,
	],
	declarations: [MaintenanceMessageComponent],
	exports: [MaintenanceMessageComponent],
})
export class MaintenanceMessageModule {}
