import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormValidation } from '../../shared/form-validation';
import { UserTypeEnumeration } from '../../shared/enumeration/user-type.enumeration';
import { StringsUtils } from 'nc-utils';

const OK = null;
const NOT_EQUAL_REPEAT_PASSWORD = { notEqualRepeatPassword: true };
const MANDATORY_PASSWORD = { required: true };
const REQUIRED_COMPANY = { requiredCompany: true };
const NUMBER = /^[0-9]+$/;
const NOT_NUMBER_NEO_ID = { numberForNeoID: true };
const PHONE_NUMBER_MANDATORY = { phoneNumberMandatory: true };

const mandatoryCompany = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const isUserType = control.get('type');
		const company = control.get('companies');

		return isUserType && isUserType.value === UserTypeEnumeration.HR.toUpperCase() && !company.value ? REQUIRED_COMPANY : OK;
	};
};

const passwordRequired = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const password = control.get('newPassword');
		const id = control.get('id');

		if (!id.value && StringsUtils.isEmpty(password.value)) {
			FormValidation.addError(password, MANDATORY_PASSWORD);
		} else {
			FormValidation.removeError(password, MANDATORY_PASSWORD);
		}

		return OK;
	};
};

const notEqualRepeatPassword = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const password = control.get('newPassword');
		const repeatedPassword = control.get('repeatedPassword');

		if (password && repeatedPassword && password.value !== repeatedPassword.value) {
			FormValidation.addError(repeatedPassword, NOT_EQUAL_REPEAT_PASSWORD);
		} else {
			FormValidation.removeError(repeatedPassword, NOT_EQUAL_REPEAT_PASSWORD);
		}

		return OK;
	};
};
const isNumber = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;
		if (value && !NUMBER.test(value)) {
			return NOT_NUMBER_NEO_ID;
		}
	};
};
const mobileNumberRequired = (): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const selectedUser = control.get('type');
		const phoneNumber = control.get('mobileNumber');
		if (!phoneNumber.value && (selectedUser.value == 'HR' || selectedUser.value == 'HR_KLE' || selectedUser.value == 'BRANCH_HR')) {
			FormValidation.addError(phoneNumber, PHONE_NUMBER_MANDATORY);
		} else {
			FormValidation.removeError(phoneNumber, PHONE_NUMBER_MANDATORY);
		}

		return OK;
	};
};
export { passwordRequired, mandatoryCompany, notEqualRepeatPassword, isNumber, mobileNumberRequired };
