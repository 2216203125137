import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import { ColumnBuilder, DatatableColumn, TableData } from 'nc-datatable';
import { BehaviorSubject } from 'rxjs';
import { IncapacityToWork } from '../../model/incapacity-to-work';
import { IncapacityService } from '../../service/incapacity.service';
import { LabelWithParameters } from 'nc-shared';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { IncapacityFormGuardDialogComponent } from './incapacity-form-guard-dialog/incapacity-form-guard-dialog.component';
import { DialogConfigurationUtils, DialogService } from 'nc-utils';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AbsenceService } from '../../service/absence.service';
import { NcInformationDialogComponent } from 'nc-information-dialog';
import { AbsenceStatusEnumeration } from '../../model/enumeration/absence-status.enumeration';
import { Moment } from 'moment';

@Component({
	selector: 'ea-incapacity-table',
	templateUrl: './incapacity-table.component.html',
	styleUrls: ['./incapacity-table.component.scss'],
})
export class IncapacityTableComponent implements OnInit {
	@Input() formArray: FormArray;
	@Input() isReadonly = false;
	@Input() isKleEnabled = false;
	@Input() isAccident = false;
	@Input() absenceState: string;
	@Input() branchHrLoggedIn = false;
	@Input() caseWasOpen = false;
	@Input() isFetchButtonVisible = false;
	@Input() contractStarts: Moment;
	@Input() contractEnds: Moment;
	@Output() fetchButtonVisibilityChange = new EventEmitter<boolean>();

	filterConfig: FilterConfig;

	data$: BehaviorSubject<TableData> = new BehaviorSubject<TableData>({ records: [] });
	absenceCode: string;

	constructor(
		@Inject('ValidationErrorMap') private errorMap: any,
		private incapacityService: IncapacityService,
		private dialogService: DialogService,
		private route: ActivatedRoute,
		private store: Store,
		private absenceService: AbsenceService
	) {
		this.filterConfig = {
			showGlobalFilter: true,
			filterValues: {},
		};
		this.route.params.subscribe((params) => (this.absenceCode = params.code));
	}

	private delete = (model: IncapacityToWork): void => {
		if (this.absenceCode) {
			this.dialogService
				.open(IncapacityFormGuardDialogComponent, { disableClose: true })
				.afterClosed()
				.subscribe((result) => {
					if (result) {
						if (this.isIncapacityDeleteAllowed()) {
							this.incapacityService.delete(model, this.formArray);
							this.formArray.markAsDirty();
						} else {
							this.dialogService.open(NcInformationDialogComponent, DialogConfigurationUtils.info('warningMessage', 'info'));
						}
					}
				});
		} else {
			this.incapacityService.delete(model, this.formArray);
			this.formArray.markAsDirty();
		}
	};

	edit = (model: IncapacityToWork): void => {
		this.incapacityService.openDialog(
			model,
			this.formArray,
			this.isKleEnabled,
			this.isAccident,
			this.isFetchButtonVisible,
			this.contractStarts,
			this.contractEnds
		);
	};

	openDialog = (): void => {
		this.incapacityService.openDialog(
			null,
			this.formArray,
			this.isKleEnabled,
			this.isAccident,
			this.isFetchButtonVisible,
			this.contractStarts,
			this.contractEnds
		);
	};

	ngOnInit(): void {
		this.incapacityService.initialize(this.formArray, this.data$);

		this.incapacityService.fetchButtonVisibilityChange.subscribe((isFetchButtonVisible) => {
			this.isFetchButtonVisible = isFetchButtonVisible;
			this.fetchButtonVisibilityChange.emit(isFetchButtonVisible);
		});
	}

	getColumns(): Array<DatatableColumn> {
		const columns: Array<DatatableColumn> = [
			ColumnBuilder.createHidden('id'),
			ColumnBuilder.createHidden('index'),
			ColumnBuilder.createDate('validFrom', 'validFrom'),
			ColumnBuilder.createDate('validTo', 'validTo'),
			ColumnBuilder.create('percentage', 'percentage'),
			ColumnBuilder.create('comment', 'comment'),
			ColumnBuilder.createDate('createdOn', 'createdOn'),
		];

		if (!this.isReadonly) {
			columns.push(ColumnBuilder.createAction('delete', 'delete', this.delete, { defaultIcon: 'delete' }));
		}

		return columns;
	}

	isInvalid(): boolean {
		let isInvalid = false;
		const errors = this.formArray.errors;
		if (errors) {
			const errorKeys = Object.keys(errors);
			isInvalid = errorKeys.length > 0;
		}

		for (let i = 0; i < this.formArray.length; i++) {
			if (this.formArray.at(i).get('deleted').value !== true) {
				isInvalid ||= this.formArray.at(i).invalid;
			}
		}
		return isInvalid;
	}

	getErrorMessage(): LabelWithParameters {
		if (!this.isInvalid()) {
			return null;
		}

		let errorLabel: LabelWithParameters = null;
		const errors = this.formArray.errors;

		if (errors) {
			const key = Object.keys(errors)[0];
			const params = errors[key];

			const error = this.errorMap[key];
			if (error) {
				const label = error.label;
				const value = error.value(params);

				errorLabel = { label, parameters: value };
			} else {
				errorLabel = { label: key, parameters: params };
			}
		}
		return errorLabel;
	}

	isIncapacityDeleteAllowed(): boolean {
		return this.branchHrLoggedIn ? this.absenceState !== AbsenceStatusEnumeration.OPEN.code && !this.caseWasOpen : true;
	}
}
