import { Injectable } from '@angular/core';
import { EnvironmentLoader } from '../../core/config/environment-loader';
import { HttpClient } from '@angular/common/http';
import { RequestModelFactory } from '../../label-management/service/request-model.factory';
import { MaintenanceMessage } from '../model/maintenance-message';
import { ResponseModel } from '../../shared/model/response.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class MaintenanceMessageService {
	private readonly MAINTENANCE_MESSAGE_URL = `${this.environmentLoader.getEnvironment().ABCMAN_API_URL}/maintenance-message`;

	constructor(private httpClient: HttpClient, private factory: RequestModelFactory, private environmentLoader: EnvironmentLoader) {}

	/**
	 * Gets maintenance message
	 */
	get(): Observable<ResponseModel<MaintenanceMessage>> {
		return this.httpClient.get<ResponseModel<MaintenanceMessage>>(`${this.MAINTENANCE_MESSAGE_URL}`);
	}

	getMaintenanceMessage(): Observable<MaintenanceMessage> {
		return this.httpClient.get<ResponseModel<MaintenanceMessage>>(this.MAINTENANCE_MESSAGE_URL).pipe(map((response) => response.value));
	}
	/**
	 * Updates existing or creates a new maintenance message
	 */
	save(maintenanceMessage: MaintenanceMessage): Observable<ResponseModel<any>> {
		return this.httpClient.post<ResponseModel<any>>(this.MAINTENANCE_MESSAGE_URL, maintenanceMessage);
	}
}
