<div id="upload-user-dialog" fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="10px">
	<form [formGroup]="formGroup" fxFill fxLayout="column">
		<nc-container [title]="'chooseFile' | translate" fxLayout="column" fxLayoutGap="20px">
			<div>
				<nc-select
					fxFlex="70%"
					id="fileType"
					label="fileContent"
					formControlName="fileContent"
					[formGroup]="formGroup"
					[data$]="fileContent$"
					[translate]="true"
				>
				</nc-select>
			</div>
			<div>
				<nc-file-upload id="file" [formGroup]="formGroup" formControlName="file" label="file" fxFlex="70%"></nc-file-upload>
			</div>
			<div>
				<nc-submit-button
					id="submit"
					(buttonClick)="send()"
					dialogLabel="processing"
					[formGroup]="formGroup"
					buttonLabel="submit"
					[disabled]="!fileControl.value"
				>
				</nc-submit-button>
			</div>
		</nc-container>
	</form>
	<ea-container *ngIf="showResults" [title]="'result' | translate" fxLayout="column" fxLayoutGap="20px">
		<div>
			<p>
				<b>{{ 'success' | translate }}: {{ successCount }}</b>
			</p>
			<p>
				<b>{{ 'failure' | translate }}: {{ failureCount }}</b>
			</p>
		</div>
		<div *ngIf="showUserResults">
			<nc-datatable
				[tableHeader]="'validations'"
				id="moreUserInfo"
				[columns]="userColumns"
				[data$]="failureItems$"
				[filterConfig]="filterConfig"
			></nc-datatable>
		</div>
		<div *ngIf="showCompanyResults">
			<nc-datatable
				[tableHeader]="'validations'"
				id="moreCompanyInfo"
				[columns]="companyColumns"
				[data$]="failureItems$"
				[filterConfig]="filterConfig"
			></nc-datatable>
		</div>
	</ea-container>
	<div mat-dialog-actions fxLayout="row wrap">
		<nc-button id="close" label="close" fxLayoutAlign="start" [mat-dialog-close]="false"></nc-button>
	</div>
</div>
