<form fxLayout="column" fxFill [formGroup]="formGroup">
	<nc-main-content>
		<nc-form-content>
			<div fxLayout="column" fxLayoutGap="10px">
				<nc-input id="german" [formGroup]="formGroup" formControlName="de" label="german"></nc-input>
				<nc-input id="english" [formGroup]="formGroup" formControlName="en" label="english"></nc-input>
				<nc-input id="french" [formGroup]="formGroup" formControlName="fr" label="french"></nc-input>
				<nc-input id="italian" [formGroup]="formGroup" formControlName="it" label="italian"></nc-input>
				<nc-date-time-picker id="dateFrom" [formGroup]="formGroup" formControlName="dateFrom" label="maintenanceMessageDateFrom">
				</nc-date-time-picker>
				<nc-date-time-picker id="dateTo" [formGroup]="formGroup" formControlName="dateTo" label="maintenanceMessageDateTo">
				</nc-date-time-picker>
			</div>
		</nc-form-content>
	</nc-main-content>
	<ea-footer [formGroup]="formGroup" [onSave]="onSubmit"></ea-footer>
</form>
