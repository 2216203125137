import { createAction, props } from '@ngrx/store';
import { MaintenanceMessage } from '../../model/maintenance-message';

export const getMaintenanceMessageSuccess = createAction(
	'[MaintenanceMessage] Get Maintenance message  success',
	props<{
		message: MaintenanceMessage;
	}>()
);

export const saveMaintenanceMessageSuccess = createAction('[MaintenanceMessage] Save Maintenance message success', props<{ response: any }>());
