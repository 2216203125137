import { Component, OnInit } from '@angular/core';
import * as moment from 'moment/moment';
import { ApiDateTimeFormat, parseDate } from 'nc-utils';
import { MaintenanceMessage } from '../../../maintenance-message/model/maintenance-message';
import { MaintenanceMessageService } from '../../../maintenance-message/service/maintenance-message.service';
import { LanguageEnumeration } from '../../enumeration/language.enumeration';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { SettingsSelect } from '../../../state/settings/action';
import { Subject } from 'rxjs';

@Component({
	selector: 'ea-banner',
	templateUrl: './banner.component.html',
	styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
	maintenanceMessage?: MaintenanceMessage;
	selectedLanguage = '';
	message = '';
	destroy$ = new Subject<void>();

	constructor(private store: Store, private maintenanceMessageService: MaintenanceMessageService) {}

	ngOnInit() {
		this.getMaintenanceMessage();
	}

	getMaintenanceMessage(): void {
		this.maintenanceMessageService
			.getMaintenanceMessage()
			.pipe(
				takeUntil(this.destroy$),
				switchMap((maintenanceMessage) => {
					this.maintenanceMessage = maintenanceMessage;
					return this.store.select(SettingsSelect.getLanguage).pipe(takeUntil(this.destroy$));
				}),
				tap((language) => {
					this.selectedLanguage = language;
					this.message = this.getMaintenanceMessageForSelectedLanguage();
				})
			)
			.subscribe();
	}

	showMessage(): boolean {
		const bannerDateFrom = parseDate(this.maintenanceMessage?.dateFrom, ApiDateTimeFormat);
		const bannerDateTo = parseDate(this.maintenanceMessage?.dateTo, ApiDateTimeFormat);
		const currentDate = moment();
		return currentDate.isSameOrAfter(bannerDateFrom) && currentDate.isSameOrBefore(bannerDateTo);
	}

	getMaintenanceMessageForSelectedLanguage(): string {
		switch (this.selectedLanguage) {
			case 'de':
				return this.maintenanceMessage?.de ?? '';
			case 'en':
				return this.maintenanceMessage?.en ?? '';
			case 'fr':
				return this.maintenanceMessage?.fr ?? '';
			case 'it':
				return this.maintenanceMessage?.it ?? '';
			default:
				return this.maintenanceMessage?.de ?? '';
		}
	}
}
