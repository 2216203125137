import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserModel } from '../model/user.model';
import { ActionResponse, SaveResponse } from 'nc-utils';
import { EnvironmentLoader } from '../../core/config/environment-loader';

@Injectable({
	providedIn: 'root',
})
export class UserAdministrationService {
	constructor(private httpClient: HttpClient, private environmentLoader: EnvironmentLoader) {}

	private readonly API_URL = this.environmentLoader.getEnvironment().ABCMAN_API_URL;
	private readonly GET_USERS_URL = `${this.API_URL}/users`;
	private readonly GENERATE_PASSWORD_URL = `${this.API_URL}/password/generate-random`;
	private readonly INSERT_USERS_FROM_EXCEL = `${this.API_URL}/users/insert-users/{fileType}`;

	/**
	 * Get all users
	 */
	getAllUsers(): Observable<{ [key: string]: string }[]> {
		return this.httpClient.get<{ [key: string]: string }[]>(this.GET_USERS_URL);
	}

	/**
	 * Get user by id
	 * @param id Unique identifier for user
	 */
	getUser(id: number): Observable<UserModel> {
		return this.httpClient.get<UserModel>(`${this.GET_USERS_URL}/${id}`);
	}

	/**
	 * Generate random password
	 */
	generatePassword(): Observable<any> {
		return this.httpClient.post<any>(this.GENERATE_PASSWORD_URL, null);
	}

	/**
	 * Activate user
	 * @param id Unique identifier for user
	 */
	activateUser(id: number): Observable<ActionResponse> {
		return this.httpClient.put<ActionResponse>(`${this.GET_USERS_URL}/${id}/activate`, null);
	}

	/**
	 * Deactivate user
	 * @param id Unique identifier for user
	 */
	deactivateUser(id: number): Observable<ActionResponse> {
		return this.httpClient.put<ActionResponse>(`${this.GET_USERS_URL}/${id}/deactivate`, null);
	}

	/**
	 * Delete user
	 * @param id Unique identifier for user
	 */
	deleteUser(id: number): Observable<any> {
		return this.httpClient.request('delete', this.GET_USERS_URL, { body: [id] });
	}

	/**
	 * Save user
	 * @param user Form data
	 */
	saveUser(user: UserModel): Observable<SaveResponse> {
		return this.httpClient.post<SaveResponse>(this.GET_USERS_URL, user);
	}

	/**
	 * Upload excel file with user info in order to create new users. Receive number of success and failure records and validations info for failure.
	 * @param file Excel file
	 * @param fileContent Chosen file content EasyTemp / Neo  companies
	 */
	insertUsersFromExcel(file: File, fileContent: string) {
		const formData = new FormData();
		formData.append('file', file);
		const url = this.INSERT_USERS_FROM_EXCEL.replace('{fileType}', fileContent);

		return this.httpClient.post<any>(url, formData);
	}
}
