import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule } from '@angular/router';
import { LoginComponent } from './user/component/login/login.component';
import { UnavailableComponent } from './shared/component/error/unavailable/unavailable.component';
import { NotFoundComponent } from './shared/component/error/not-found/not-found.component';
import { ForgotPasswordComponent } from './user/component/forgot-password/forgot-password.component';
import { ForgotPasswordVerificationComponent } from './user/component/forgot-password-verification/forgot-password-verification.component';
import { AppReadyGuard } from './app-ready.guard';
import { LoginGuard } from './user/guard/login.guard';
import { LoginVerificationComponent } from './user/component/login-verification/login-verification.component';
import { AuthenticatedGuard } from './user/guard/authenticated.guard';
import { UnauthenticatedGuard } from './user/guard/unauthenticated.guard';
import { ResetPasswordComponent } from './user/component/reset-password/reset-password.component';
import { ResetPasswordGuard } from './user/guard/reset-password.guard';
import { PersonalDataComponent } from './user/component/personal-data/personal-data.component';
import { ChangePasswordComponent } from './user/component/change-password/change-password.component';
import { ArchivedDashboardComponent } from './dashboard/component/archived-dashboard/archived-dashboard.component';
import { ExcelStatisticComponent } from './statistic/component/excel-statistic/excel-statistic.component';
import { WordStatisticComponent } from './statistic/component/word-statistic/word-statistic.component';
import { MilestoneSettingsComponent } from './milestone-settings/component/milestone-settings/milestone-settings.component';
import { MilestoneSettingsFormComponent } from './milestone-settings/component/milestone-settings-form/milestone-settings-form.component';
import { VersionComponent } from './shared/component/version/version.component';
import { AdministrationComponent } from './administration/component/administration/administration.component';
import { CompanyDetailsComponent } from './integration/component/company-details/company-details.component';
import { TaskComponent } from './task/component/tasks/task.component';
import { NoteFormComponent } from './task/component/note-form/note-form.component';
import { TaskFormComponent } from './task/component/task-form/task-form.component';
import { MilestoneFormComponent } from './task/component/milestone-form/milestone-form.component';
import { PreviewDocumentComponent } from './shared/component/preview-document/preview-document.component';
import { LabelManagementComponent } from './label-management/component/label-management.component';
import { LabelManagementFormComponent } from './label-management/component/label-management-form/label-management-form.component';
import { HistoryComponent } from './absence/component/history/history.component';
import { AuthorityComponent } from './access-management/component/authority/authority.component';
import { AuthorityFormComponent } from './access-management/component/authority-form/authority-form.component';
import { ReportComponent } from './report/component/report.component';
import { ReassignAbsenceComponent } from './absence/component/reassign-absence/reassign-absence.component';
import { UserAdministrationComponent } from './user-administration/component/user-administration/user-administration.component';
import { UserAdministrationFormComponent } from './user-administration/component/user-administration-form/user-administration-form.component';
import { CompanyComponent } from './integration/component/company/company.component';
import { CompanyFormComponent } from './integration/component/company-form/company-form.component';
import { NewAbsenceComponent } from './absence/component/absence/new-absence/new-absence.component';
import { ActiveDashboardComponent } from './dashboard/component/active-dashboard/active-dashboard.component';
import { ExistingAbsenceComponent } from './absence/component/absence/existing-absence/existing-absence.component';
import { ExistingAbsenceAnnexComponent } from './absence/component/absence-anex/existing-absence-annex/existing-absence-annex.component';
import { NewAbsenceAnnexFormComponent } from './absence/component/absence-anex/absence-annex-form/new-absence-annex-form.component';
import { AccessAssignmentComponent } from './access-assignment/component/access-assignment.component';
import { CompanyGroupsComponent } from './integration/component/company-groups/company-groups.component';
import { CompanyGroupDetailsComponent } from './integration/component/company-group-details/company-group-details.component';
import { DocumentTypesFormComponent } from './document-types/component/document-types-form/document-types-form.component';
import { DocumentTypesComponent } from './document-types/component/document-types.component';
import { NotificationsComponent } from './notifications/component/notifications.component';
import { CanDeactivateGuard } from 'nc-shared';
import { AnexGuard } from './user/guard/anex.guard';
import { LdResponsibleComponent } from './ld-responsible/component/ld-responsible.component';
import { LdResponsibleFormComponent } from './ld-responsible/component/ld-responsible-form/ld-responsible-form.component';
import { MedicalInstitutionsComponent } from './integration/component/medical-institutions/medical-institutions.component';
import { KleComponent } from './kle/component/kle/kle.component';
import { KleDetailsComponent } from './kle/component/kle-details/kle-details.component';
import { CanLeaveMilestonePageGuard } from './user/guard/can-leave-milestone-page.guard';
import { RegisterOrganizationComponent } from './kle/component/register-organization/register-organization.component';
import { RegisterOrganizationFormComponent } from './kle/component/register-organization/form/register-organization-form.component';
import { AccidentDescriptionComponent } from './kle/component/accident-description/accident-description.component';
import { TreatmentsComponent } from './kle/component/treatments/treatments.component';
import { MaintenanceMessageComponent } from './maintenance-message/component/maintenance-message.component';

@NgModule({
	imports: [
		RouterModule.forRoot(
			[
				{
					path: '',
					component: LoginComponent,
					canActivate: [AppReadyGuard, UnauthenticatedGuard],
					data: { title: 'login' },
				},
				{
					path: 'status/info',
					component: VersionComponent,
					canActivate: [AppReadyGuard, UnauthenticatedGuard],
					data: { title: 'Version' },
				},
				{
					path: 'verification',
					component: LoginVerificationComponent,
					canActivate: [AppReadyGuard, LoginGuard],
					data: { title: 'login' },
				},
				{
					path: 'forgot/password/verify',
					component: ForgotPasswordVerificationComponent,
					canActivate: [AppReadyGuard, UnauthenticatedGuard],
					data: { title: 'forgotPassword' },
				},
				{
					path: 'forgot/password',
					component: ForgotPasswordComponent,
					canActivate: [AppReadyGuard, UnauthenticatedGuard],
					data: { title: 'forgotPassword' },
				},
				{
					path: 'reset/password',
					component: ResetPasswordComponent,
					canActivate: [AppReadyGuard, ResetPasswordGuard],
					data: { title: 'newPassword' },
				},
				{
					path: 'dashboard',
					component: ActiveDashboardComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'dashboard' },
				},
				{
					path: 'history',
					component: ArchivedDashboardComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'history' },
				},
				{
					path: 'notifications',
					component: NotificationsComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'notifications' },
				},
				{
					path: 'maintenance-message',
					component: MaintenanceMessageComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'maintenanceMessage' },
				},
				{
					path: 'statistic/excel',
					component: ExcelStatisticComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'statistic' },
				},
				{
					path: 'statistic/word',
					component: WordStatisticComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'statistic' },
				},
				{
					path: 'settings/personal-data',
					component: PersonalDataComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'personalData' },
				},
				{
					path: 'settings/change-password',
					component: ChangePasswordComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'changePassword' },
				},
				{
					path: 'absence/new',
					component: NewAbsenceComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'newCase' },
				},
				{
					path: 'absence/:code',
					component: ExistingAbsenceComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'singleAbsencePage' },
				},
				{
					path: 'absence/:code/tasks',
					component: TaskComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'tasks' },
				},
				{
					path: 'absence/:code/kle',
					component: KleDetailsComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'kle' },
				},
				{
					path: 'absence/:code/milestone/:id',
					component: MilestoneFormComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'milestone' },
				},
				{
					path: 'absence/:code/open-milestone/:milestoneSettingsId',
					component: MilestoneFormComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					canDeactivate: [CanLeaveMilestonePageGuard],
					data: { title: 'milestone' },
				},
				{
					path: 'absence/:code/note/new',
					component: NoteFormComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'note' },
				},
				{
					path: 'absence/:code/note/:id',
					component: NoteFormComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'note' },
				},
				{
					path: 'absence/:code/tasks/new',
					component: TaskFormComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'task' },
				},
				{
					path: 'absence/:code/tasks/:id',
					component: TaskFormComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'task' },
				},
				{
					path: 'document/:filename/:documentId',
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					component: PreviewDocumentComponent,
					data: { title: 'documents' },
				},
				{
					path: 'administration',
					component: AdministrationComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'administration' },
				},
				{
					path: 'company/:id',
					component: CompanyDetailsComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'companyDetails' },
				},
				{
					path: 'milestone/settings',
					component: MilestoneSettingsComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'overviewMilestoneSettings' },
				},
				{
					path: 'milestone/settings/new',
					component: MilestoneSettingsFormComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'milestoneSettings' },
				},
				{
					path: 'milestone/settings/:id',
					component: MilestoneSettingsFormComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'milestoneSettings' },
				},
				{
					path: 'label/management',
					component: LabelManagementComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'labelManagement' },
				},
				{
					path: 'label/management/:code',
					component: LabelManagementFormComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'editLabel' },
				},
				{
					path: 'activity-log',
					component: ReportComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'activityLog' },
				},
				{
					path: 'report',
					component: ReportComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'report' },
				},
				{
					path: 'absence/:code/history',
					component: HistoryComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'caseHistory' },
				},
				{
					path: 'absence/:code/reassign',
					component: ReassignAbsenceComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'reassignCase' },
				},
				{
					path: 'document-types',
					component: DocumentTypesComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'documentTypes' },
				},
				{
					path: 'document-types/:code',
					component: DocumentTypesFormComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'editDocumentType' },
				},
				{
					path: 'roles',
					component: AuthorityComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'roles' },
				},
				{
					path: 'role-groups',
					component: AuthorityComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'roleGroups' },
				},
				{
					path: 'permissions',
					component: AuthorityComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'permissions' },
				},
				{
					path: 'roles/:id',
					component: AuthorityFormComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'role' },
				},
				{
					path: 'role-groups/:id',
					component: AuthorityFormComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'roleGroups' },
				},
				{
					path: 'permissions/:id',
					component: AuthorityFormComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'permission' },
				},
				{
					path: 'roles/new',
					component: AuthorityFormComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'roleGroups' },
				},
				{
					path: 'role-groups/new',
					component: AuthorityFormComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'roleGroups' },
				},
				{
					path: 'permissions/new',
					component: AuthorityFormComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'permission' },
				},
				{
					path: 'user-administration',
					component: UserAdministrationComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'userAdministration' },
				},
				{
					path: 'user-administration/new',
					component: UserAdministrationFormComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'userAdministration' },
				},
				{
					path: 'user-administration/:id',
					component: UserAdministrationFormComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'userAdministration' },
				},
				{
					path: 'integration/companies',
					component: CompanyComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'companies' },
				},
				{
					path: 'integration/companies/new',
					component: CompanyFormComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'companies' },
				},
				{
					path: 'integration/companies/:id',
					component: CompanyDetailsComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'companyDetails' },
				},
				{
					path: 'integration/medical-institutions',
					component: MedicalInstitutionsComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'medicalInstitutions' },
				},
				{
					path: 'kle',
					component: KleComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'kle' },
				},
				{
					path: 'user/roles',
					component: AccessAssignmentComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'userRole' },
				},
				{
					path: 'user/role-groups',
					component: AccessAssignmentComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'userRoleGroup' },
				},
				{
					path: 'role/permissions',
					component: AccessAssignmentComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'rolePermision' },
				},
				{
					path: 'role/document-types',
					component: AccessAssignmentComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'roleDocumentType' },
				},
				{
					path: 'role-group/roles',
					component: AccessAssignmentComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'roleGroupRole' },
				},
				{
					path: 'user/allow-permissions',
					component: AccessAssignmentComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'userAllowPermission' },
				},
				{
					path: 'user/deny-permissions',
					component: AccessAssignmentComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'userDenyPermission' },
				},
				{
					path: 'user/allow-document-types',
					component: AccessAssignmentComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'userAllowDocumentType' },
				},
				{
					path: 'user/deny-document-types',
					component: AccessAssignmentComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'userDenyDocumentType' },
				},
				{
					path: 'integration/company/groups',
					component: CompanyGroupsComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'companyGroups' },
				},
				{
					path: 'integration/company/groups/new',
					component: CompanyGroupDetailsComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'companyGroup' },
				},
				{
					path: 'integration/company/groups/:id',
					component: CompanyGroupDetailsComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'companyGroup' },
				},
				{
					path: 'absence/:code/share-information',
					component: NewAbsenceAnnexFormComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard, AnexGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'shareInformation' },
				},
				{
					path: 'absence/:code/prolongation',
					component: NewAbsenceAnnexFormComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard, AnexGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'prolongation' },
				},
				{
					path: 'absence/:code/closed',
					component: NewAbsenceAnnexFormComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard, AnexGuard],
					canDeactivate: [CanDeactivateGuard],
					data: { title: 'close' },
				},
				{
					path: 'share-information/:identifier',
					component: ExistingAbsenceAnnexComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'shareInformation' },
				},
				{
					path: 'annex/prolongation/:identifier',
					component: ExistingAbsenceAnnexComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'prolongation' },
				},
				{
					path: 'annex/closed/:identifier',
					component: ExistingAbsenceAnnexComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'close' },
				},
				{
					path: 'ld-responsibles',
					component: LdResponsibleComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'ldResponsible' },
				},
				{
					path: 'ld-responsibles/new',
					component: LdResponsibleFormComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'ldResponsible' },
				},
				{
					path: 'ld-responsibles/:id',
					component: LdResponsibleFormComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'editLdResponsible' },
				},
				{
					path: 'kle/:code/payments',
					component: LdResponsibleFormComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'editLdResponsible' },
				},
				{
					path: 'kle/companies',
					component: RegisterOrganizationComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'companies' },
				},
				{
					path: 'kle/register-organization/:companyId',
					component: RegisterOrganizationFormComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'registerOrganization' },
				},
				{
					path: 'kle/:code/accident-description',
					component: AccidentDescriptionComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'accidentDescription' },
				},
				{
					path: 'kle/:code/treatments',
					component: TreatmentsComponent,
					canActivate: [AppReadyGuard, AuthenticatedGuard],
					data: { title: 'treatments' },
				},
				{
					path: 'unavailable',
					component: UnavailableComponent,
					data: { title: 'unavailable' },
				},
				{
					path: '404',
					component: NotFoundComponent,
					data: { title: '404' },
				},
				{
					path: '',
					redirectTo: '',
					pathMatch: 'full',
				},
				{ path: '**', redirectTo: '404' },
			],
			{ preloadingStrategy: NoPreloading }
		),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
