export enum PermissionEnumeration {
	FORCING_PASSWORD_CHANGE = 'FORCING_PASSWORD_CHANGE',
	CM_ADMIN = 'CM_ADMIN',
	ADMIN = 'ADMIN',
	HR = 'HR',
	BRANCH_HR = 'BRANCH_HR',
	INTEGRATION_UPDATE_COMPANY = 'INTEGRATION_UPDATE_COMPANY',
	DECLARE_INCIDENT = 'DECLARE_INCIDENT',
	HR_KLE = 'HR_KLE',
	ZENDESK = 'ZENDESK',
}
