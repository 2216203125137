import * as ApplicationState from '../../state/app.state';
import { MaintenanceMessage } from '../model/maintenance-message';

export interface MaintenanceMessageState {
	message: MaintenanceMessage;
}

export interface State extends ApplicationState.State {
	maintenanceMessage: MaintenanceMessageState;
}

export const MAINTENANCE_MESSAGE_MANAGEMENT_INITIAL_STATE: MaintenanceMessageState = {
	message: null,
};
