import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { appMetaReducers, appReducers } from './state/app.state.persistence';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { LabelTranslateLoader } from './shared/service/label-translate-loader';
import { ReadyService } from './shared/service/ready.service';
import { ErrorHandlerService } from './shared/service/error-handler.service';
import { UserModule } from './user/user.module';
import { ErrorMessageMap } from './shared/error/error-message-map';
import { DashboardModule } from './dashboard/dashboard.module';
import { SettingsEffect } from './state/settings/settings.effect';
import { RequestHttpInterceptor } from './shared/http/request-http-interceptor.service';
import { ErrorResponseHttpInterceptor } from './shared/http/error-response-http-interceptor.service';
import { ErrorEffect } from './state/error/error.effect';
import { StatisticModule } from './statistic/statistic.module';
import { AbsenceModule } from './absence/absence.module';
import { MilestoneSettingsModule } from './milestone-settings/milestone-settings.module';
import { AdministrationModule } from './administration/administration.module';
import { TaskModule } from './task/task.module';
import { LabelManagementModule } from './label-management/label-management.module';
import { AccessManagementModule } from './access-management/access-management.module';
import { ReportModule } from './report/report.module';
import { UserAdministrationModule } from './user-administration/user-administration.module';
import { IntegrationModule } from './integration/integration.module';
import { AccessAssignmentModule } from './access-assignment/access-assignment.module';
import { LdResponsibleModule } from './ld-responsible/ld-responsible.module';
import { DocumentTypesModule } from './document-types/document-types.module';
import { NotificationsModule } from './notifications/notifications.module';
import { KleModule } from './kle/kle.module';
import { NgxZendeskWebwidgetModule } from 'ngx-zendesk-webwidget';
import { ZendeskConfig } from './zendesk.config';
import { FormsModule } from '@angular/forms';
import { EnvironmentLoader } from './core/config/environment-loader';
import { MaintenanceMessageModule } from './maintenance-message/maintenance-message.module';

@NgModule({
	declarations: [AppComponent],
	imports: [
		FormsModule,
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,
		NgxZendeskWebwidgetModule.forRoot(ZendeskConfig),
		EffectsModule.forRoot([SettingsEffect, ErrorEffect]),
		StoreModule.forRoot(appReducers, { metaReducers: appMetaReducers }),
		TranslateModule.forRoot({
			defaultLanguage: 'de',
			loader: {
				provide: TranslateLoader,
				useClass: LabelTranslateLoader,
				deps: [HttpClient, ReadyService, ErrorHandlerService, EnvironmentLoader],
			},
		}),
		UserModule,
		SharedModule,
		AbsenceModule,
		DashboardModule,
		StatisticModule,
		AdministrationModule,
		MilestoneSettingsModule,
		TaskModule,
		MilestoneSettingsModule,
		LdResponsibleModule,
		LabelManagementModule,
		AccessManagementModule,
		ReportModule,
		UserAdministrationModule,
		IntegrationModule,
		AccessAssignmentModule,
		DocumentTypesModule,
		NotificationsModule,
		KleModule,
		MaintenanceMessageModule,
	],
	providers: [
		{ provide: 'ValidationErrorMap', useValue: ErrorMessageMap },
		{ provide: HTTP_INTERCEPTORS, useClass: RequestHttpInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorResponseHttpInterceptor, multi: true },
	],
	exports: [],
	bootstrap: [AppComponent],
})
export class AppModule {}
