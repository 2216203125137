import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { UserAdministrationComponent } from './component/user-administration/user-administration.component';
import { UserAdministrationFormComponent } from './component/user-administration-form/user-administration-form.component';
import { EffectsModule } from '@ngrx/effects';
import { USER_ADMINISTRATION_FEATURE_STATE_NAME } from './state/action/user-administration.select';
import { userAdministrationReducer } from './state/user-administration.reducer';
import { UserAdministrationEffect } from './state/user-administration.effect';
import { StoreModule } from '@ngrx/store';
import { NcChipsInputModule } from 'nc-chips-input';
import { NcBannerComponent } from './component/ea-banner/nc-banner.component';
import { UploadUserDialogComponent } from './component/upload-user-dialog/upload-user-dialog.component';
import { ConfirmChangeTypeDialogComponent } from './component/confirm-change-type/confirm-change-type-dialog.component';

@NgModule({
	imports: [
		SharedModule,
		EffectsModule.forFeature([UserAdministrationEffect]),
		StoreModule.forFeature(USER_ADMINISTRATION_FEATURE_STATE_NAME, userAdministrationReducer),
		NcChipsInputModule,
	],
	declarations: [
		UserAdministrationComponent,
		UserAdministrationFormComponent,
		NcBannerComponent,
		UploadUserDialogComponent,
		ConfirmChangeTypeDialogComponent,
	],
	exports: [UserAdministrationComponent, UserAdministrationFormComponent, NcBannerComponent, UploadUserDialogComponent],
})
export class UserAdministrationModule {}
