import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BaseComponent, Option } from 'nc-shared';
import { UserAdministrationService } from '../../service/user-administration.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ColumnBuilder, DatatableColumn, TableData } from 'nc-datatable';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { DialogConfigurationUtils, DialogService, DialogType, EnumUtils } from 'nc-utils';
import { NcInformationDialogComponent } from 'nc-information-dialog';
import { FileUtils } from '../../../shared/utils/file.utils';
import { FileExcelTypeEnum } from '../../model/file-excel-type.enum';

@Component({
	templateUrl: './upload-user-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadUserDialogComponent extends BaseComponent implements OnInit {
	formGroup: FormGroup;
	userColumns: Array<DatatableColumn>;
	companyColumns: Array<DatatableColumn>;
	filterConfig: FilterConfig;
	failureItems$: BehaviorSubject<TableData> = new BehaviorSubject<TableData>({ records: [] });
	fileContent$: Observable<Option[]>;
	successCount = 0;
	failureCount = 0;
	showResults = false;
	showUserResults = false;
	showCompanyResults = false;
	fileControl: FormControl;

	constructor(
		formBuilder: FormBuilder,
		private service: UserAdministrationService,
		private dialogService: DialogService,
		private cdr: ChangeDetectorRef
	) {
		super();
		this.formGroup = formBuilder.group({
			file: [''],
			fileContent: [''],
		});
	}

	ngOnInit(): void {
		this.fileControl = this.formGroup.get('file') as FormControl;
		this.fileControl.valueChanges.subscribe(() => {
			this.showResults = false;
			this.showCompanyResults = false;
			this.showUserResults = false;
			this.cdr.detectChanges();
		});

		this.filterConfig = {
			showGlobalFilter: false,
			filterValues: {},
		};

		this.userColumns = this.getUserColumns();
		this.companyColumns = this.getCompanyColumns();
		this.fileContent$ = EnumUtils.toObservableItems(FileExcelTypeEnum);
	}

	getUserColumns(): Array<DatatableColumn> {
		return [
			ColumnBuilder.create('userType', 'userType'),
			ColumnBuilder.create('firstName', 'firstName'),
			ColumnBuilder.create('lastName', 'lastName'),
			ColumnBuilder.create('username', 'username'),
			ColumnBuilder.create('email', 'email'),
			ColumnBuilder.create('errorMessage', 'validation'),
		];
	}

	getCompanyColumns(): Array<DatatableColumn> {
		return [
			ColumnBuilder.create('username', 'username'),
			ColumnBuilder.create('easyTempId', 'easyTempId'),
			ColumnBuilder.create('contractType', 'contractType'),
			ColumnBuilder.create('neoId', 'neoId'),
			ColumnBuilder.create('errorMessage', 'validation'),
		];
	}

	send() {
		const file = this.fileControl.value.files[0] as File;
		const fileContent = this.formGroup.get('fileContent').value;

		if (file && (FileUtils.getExtension(file.name) === 'xls' || FileUtils.getExtension(file.name) === 'xlsx')) {
			this.service.insertUsersFromExcel(file, fileContent).subscribe((response) => {
				this.dialogService.close(DialogType.PROCESSING);

				if (response.success) {
					this.showResults = true;
					this.successCount = response.value.success;
					this.failureCount = response.value.failed;

					if (this.failureCount > 0) {
						this.showUserResults =
							response.value.fileType == FileExcelTypeEnum.EASY_TEMP_USERS.code ||
							response.value.fileType == FileExcelTypeEnum.NEO_USERS.code;
						this.showCompanyResults =
							response.value.fileType == FileExcelTypeEnum.EASY_TEMP_RELATED_COMPANY.code ||
							response.value.fileType == FileExcelTypeEnum.NEO_RELATED_COMPANY.code;
					}

					this.failureItems$.next({ records: response.value.unsuccessfulRows });
					this.cdr.detectChanges();
				} else {
					this.dialogService.open(NcInformationDialogComponent, DialogConfigurationUtils.error(response.errors[0].label));
				}
			});
		} else {
			this.dialogService.close(DialogType.PROCESSING);
			this.dialogService.open(NcInformationDialogComponent, DialogConfigurationUtils.error('fileFormatNotSupported'));
		}
	}
}
