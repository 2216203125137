import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Absence } from '../../../model/absence';
import { Company } from '../../../../shared/model/company';
import { AbsencePageAction } from '../../../state/action';
import { Store } from '@ngrx/store';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Employee } from '../../../../shared/model/employee';
import { AbsenceFormService } from '../../../service/absence-form.service';
import { AppEvent } from 'src/app/shared/model/app-event.model';
import { EventQueueService } from 'src/app/shared/service/event-queue.service';
import { BroadcastEventType } from '../../../../shared/enumeration/broadcast-event-type.enumeration';
import { DialogService } from 'nc-utils';
import { AbsenceStatusEnumeration } from '../../../model/enumeration/absence-status.enumeration';

@Component({
	selector: 'ea-absence-footer',
	templateUrl: './absence-footer.component.html',
	styleUrls: ['./absence-footer.component.scss'],
})
export class AbsenceFooterComponent {
	@Input() absence: Absence;
	@Input() company: Company;
	@Input() employee: Employee;
	@Input() permissions: string[];
	@Input() formGroup: FormGroup;
	@Input() absenceCode: string;
	@Input() fetchButtonVisible: boolean;

	isMobile: boolean;

	constructor(
		private store: Store,
		private breakpointObserver: BreakpointObserver,
		private absenceFormService: AbsenceFormService,
		private dialogService: DialogService,
		private eventQueue: EventQueueService
	) {
		this.breakpointObserver.observe(['(max-width: 900px)']).subscribe((result) => (this.isMobile = result.matches));
	}

	onArchive = (): void => {
		this.absenceFormService.checkValidityAndDispatchAction(this.formGroup, AbsencePageAction.canArchive({ id: this.absence.id }));
	};

	onActivate = (): void => {
		this.absenceFormService.checkValidityAndDispatchAction(this.formGroup, AbsencePageAction.shouldActivate());
	};

	onMobiliarDataSheet = (): void => {
		this.store.dispatch(AbsencePageAction.mobiliarDataSheet({ code: this.absenceCode }));
	};

	onFetchDocument = (): void => {
		this.absenceFormService.checkValidityAndDispatchAction(this.formGroup, AbsencePageAction.openFetchDocumentDialog());
	};

	onGenerateDocument = (): void => {
		this.absenceFormService.checkValidityAndDispatchAction(this.formGroup, AbsencePageAction.openGenerateDocumentDialog());
	};

	onHistory = (): void => {
		this.store.dispatch(AbsencePageAction.history({ code: this.absenceCode }));
	};

	onTasks = (): void => {
		this.store.dispatch(AbsencePageAction.tasks({ code: this.absenceCode }));
	};

	isGenerateButtonVisible = (): boolean => {
		const hasAbsenceTemplatePermission = this.absence.allowedDocumentTypes.includes('ABSENCE_TEMPLATE');
		const hasPowerOfAttorneyPermission = this.absence.allowedDocumentTypes.includes('POWER_OF_ATTORNEY');
		const hasPermission = hasAbsenceTemplatePermission || hasPowerOfAttorneyPermission;
		return this.absence.status !== AbsenceStatusEnumeration.ARCHIVED.code && !this.absence.merged && hasPermission;
	};

	isFetchButtonVisible = (): boolean => {
		const hasPensionPlanPermission = this.absence.allowedDocumentTypes.includes('PENSION_PLAN');
		const hasPensionCertificatePermission = this.absence.allowedDocumentTypes.includes('PENSION_CERTIFICATE');
		const hasPermission = hasPensionPlanPermission || hasPensionCertificatePermission;
		const hasCompanyNeoId = this.company.neoId != null && this.company.neoId !== 0;
		const hasEmployeeNeoId = this.employee.neoId !== null;

		return (
			this.absence.status !== AbsenceStatusEnumeration.ARCHIVED.code &&
			!this.absence.merged &&
			hasPermission &&
			this.fetchButtonVisible &&
			hasCompanyNeoId &&
			hasEmployeeNeoId
		);
	};

	isMobiliarButtonVisible = (): boolean => {
		return (
			this.permissions.includes('MOBILIAR_DATASHEET') &&
			this.absence.showMobiliarDataSheet &&
			this.formGroup.get('isRecoverageActive').value === 'no'
		);
	};

	isArchiveVisible = (): boolean => {
		return this.absence.status === AbsenceStatusEnumeration.OPEN.code && this.permissions.includes('CM_ARCHIVE_CASE');
	};

	isActivateVisible = (): boolean => {
		return this.absence.status === AbsenceStatusEnumeration.ARCHIVED.code;
	};

	isHiddenForCmUser(): boolean {
		return (
			this.permissions.includes('CASE_MANAGER') &&
			(this.absence.status === AbsenceStatusEnumeration.DRAFT.code || this.absence.status === AbsenceStatusEnumeration.PENDING_APPROVAL.code)
		);
	}

	onSubmit(): void {
		this.eventQueue.dispatch(new AppEvent(BroadcastEventType.ON_SAVE));
	}
}
