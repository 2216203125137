import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DialogConfigurationUtils, DialogService, DialogType } from 'nc-utils';
import { MaintenanceMessageService } from '../service/maintenance-message.service';
import { MaintenanceMessageApiAction, MaintenanceMessagePageAction } from './action';
import { switchMap } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { ErrorAction } from '../../state/error/action';
import { NcInformationDialogComponent } from 'nc-information-dialog';

@Injectable()
export class MaintenanceMessageEffect {
	constructor(private actions$: Actions, private maintenanceMessageService: MaintenanceMessageService, private dialogService: DialogService) {}

	getMaintenanceMessageEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MaintenanceMessagePageAction.getMaintenanceMessage),
			switchMap(() => this.maintenanceMessageService.get()),
			map((result) => MaintenanceMessageApiAction.getMaintenanceMessageSuccess({ message: result.value }))
		);
	});

	saveMaintenanceMessageEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MaintenanceMessagePageAction.saveMaintenanceMessage),
			mergeMap((action) => this.maintenanceMessageService.save(action.message)),
			map((response) =>
				response.success
					? MaintenanceMessageApiAction.saveMaintenanceMessageSuccess({ response })
					: ErrorAction.submitFailed({ message: response.errors['']['message'] })
			)
		);
	});

	saveMaintenanceMessageSuccessEffect$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(MaintenanceMessageApiAction.saveMaintenanceMessageSuccess),
				tap((action) => {
					this.dialogService.close(DialogType.PROCESSING);
					this.dialogService.open(NcInformationDialogComponent, DialogConfigurationUtils.info('done', 'info'));
				})
			);
		},
		{ dispatch: false }
	);
}
