import { ValidatorErrorCode } from './validation-error-code';
import { ValidationError } from 'nc-shared';

export const ErrorMessageMap: Record<string, ValidationError> = {
	[ValidatorErrorCode.required]: {
		label: 'mandatory.field',
		value: () => null,
	},
	[ValidatorErrorCode.invalidValidFromValidToRange]: {
		label: 'invalidValue.absence.validFrom',
		value: () => null,
	},
	[ValidatorErrorCode.invalidTakeWorkUpFullyAfterValidTo]: {
		label: 'invalidValue.absence.takeWorkFully',
		value: () => null,
	},
	[ValidatorErrorCode.invalidIncapacityDatesForContractPeriodValidFrom]: {
		label: 'invalidIncapacityDatesForContractPeriod.absence.validFrom',
		value: () => null,
	},
	[ValidatorErrorCode.invalidIncapacityDatesForContractPeriodValidTo]: {
		label: 'invalidIncapacityDatesForContractPeriod.absence.validTo',
		value: () => null,
	},
	[ValidatorErrorCode.minlength]: {
		label: 'ERROR_form_minLength',
		value: (params) => ({ minLength: params?.requiredLength }),
	},
	[ValidatorErrorCode.maxlength]: {
		label: 'ERROR_form_maxLength',
		value: (params) => ({ maxLength: params?.requiredLength }),
	},
	[ValidatorErrorCode.email]: {
		label: 'invalidFormat',
		value: () => null,
	},
	[ValidatorErrorCode.customEmail]: {
		label: 'invalidFormat',
		value: () => null,
	},
	[ValidatorErrorCode.invalidFormatPhone]: {
		label: 'invalidFormatPhone',
		value: () => null,
	},
	[ValidatorErrorCode.invalidIncapacityPercentage]: {
		label: 'invalidValue.absence.percentage',
		value: () => null,
	},
	[ValidatorErrorCode.invalidPassword]: {
		label: 'ERROR_form_password',
		value: () => null,
	},
	[ValidatorErrorCode.numberValueLength]: {
		label: 'ERROR_form_numberValueLength',
		value: (params) => ({ expectedLength: params?.requiredLength }),
	},
	[ValidatorErrorCode.pattern]: {
		label: 'ERROR_form_pattern',
		value: (params) => ({ data: params?.requiredPattern }),
	},
	[ValidatorErrorCode.mismatch]: {
		label: 'ERROR_form_mismatch',
		value: (params) => ({ data: params?.displayName }),
	},
	[ValidatorErrorCode.matDatepickerParse]: {
		label: 'ERROR_form_wrongDate',
		value: () => null,
	},
	[ValidatorErrorCode.maxFileSize]: {
		label: 'maxFileSizeExceeded',
		value: (params) => ({ maxSizeInMb: params }),
	},
	[ValidatorErrorCode.requiredFile]: {
		label: 'ERROR_file_requiredFile',
		value: () => null,
	},
	[ValidatorErrorCode.atLeastOne]: {
		label: 'ERROR_form_atLeastOne',
		value: () => null,
	},
	[ValidatorErrorCode.invalidContractNumberOrName]: {
		label: 'report.home.invalidCompanyName.or.invalidContractNo',
		value: () => null,
	},
	[ValidatorErrorCode.totalMaxFilesSize]: {
		label: 'totalMaxFilesSizeExceeded',
		value: (params) => ({ totalMaxFilesSize: params }),
	},
	[ValidatorErrorCode.uniqueName]: {
		label: 'nameIsNotUnique',
		value: () => null,
	},
	[ValidatorErrorCode.passwordsNotEqual]: {
		label: 'invalid.password.confirmationPassword',
		value: () => null,
	},
	[ValidatorErrorCode.invalidDifferenceBetweenIncapacities]: {
		label: 'invalidDifferenceBetweenDates.absence.validFrom',
		value: () => null,
	},
	[ValidatorErrorCode.incapacityRequired]: {
		label: 'invalidValue.premiumAbsence.incapacityToWork',
		value: () => null,
	},
	[ValidatorErrorCode.mandatoryAbsenceType]: {
		label: 'mandatory.milestoneSettings.absenceType',
		value: () => null,
	},
	[ValidatorErrorCode.dayMustBeGreaterThanZero]: {
		label: 'greater.then.zero.day',
		value: () => null,
	},
	[ValidatorErrorCode.noteAtLeastOneLanguage]: {
		label: 'at.least.one.language.required.note',
		value: () => null,
	},
	[ValidatorErrorCode.titleAtLeastOneLanguage]: {
		label: 'at.least.one.language.required.title',
		value: () => null,
	},
	[ValidatorErrorCode.notEqualRepeatPassword]: {
		label: 'notEqual.user.repeatPassword',
		value: () => null,
	},
	[ValidatorErrorCode.absenceAnnexDocumentsCommentRequired]: {
		label: 'mandatory.absence.annexContent.comment',
		value: () => null,
	},
	[ValidatorErrorCode.invalidPreviousDate]: {
		label: 'lastValidToNeedsToBePopulated',
		value: () => null,
	},
	[ValidatorErrorCode.passwordsAreEqual]: {
		label: 'invalid.password.newPassword',
		value: () => null,
	},
	[ValidatorErrorCode.caseManagementDetailsStartedOnDateRequired]: {
		label: 'mandatory.caseManagementStartedOnDate',
		value: () => null,
	},
	[ValidatorErrorCode.caseManagementDetailsEndedOnDateRequired]: {
		label: 'mandatory.caseManagementEndedOnDate',
		value: () => null,
	},
	[ValidatorErrorCode.caseManagementDetailsConclusionRequired]: {
		label: 'please.select.caseManagementSuccessfulnessType',
		value: () => null,
	},
	[ValidatorErrorCode.dateOfDeathError]: {
		label: 'dateOfDeathAfterAccidentDate',
		value: () => null,
	},
	[ValidatorErrorCode.lastWorkingDayError]: {
		label: 'lastWorkingDayBeforeAccidentDate',
		value: () => null,
	},
	[ValidatorErrorCode.injuriesMandatory]: {
		label: 'listOfInjuries.mandatory',
		value: () => null,
	},
	[ValidatorErrorCode.relapseDateError]: {
		label: 'relapseDateBeforeAccidentDate',
		value: () => null,
	},
	[ValidatorErrorCode.accidentDateInFuture]: {
		label: 'accidentDateInFuture',
		value: () => null,
	},
	[ValidatorErrorCode.relapseDateInFuture]: {
		label: 'relapseDateInFuture',
		value: () => null,
	},
	[ValidatorErrorCode.maintenanceMessageDates]: {
		label: 'maintenance.message.dates',
		value: () => null,
	},
	[ValidatorErrorCode.categoryMandatoryError]: {
		label: 'categoryMandatoryError',
		value: () => null,
	},
	[ValidatorErrorCode.employmentContractMandatoryFields]: {
		label: 'employmentContractMandatoryFields',
		value: () => null,
	},
	[ValidatorErrorCode.numberForNeoID]: {
		label: 'numberForNeoID',
		value: () => null,
	},
	[ValidatorErrorCode.validFromAfterDeclareIncident]: {
		label: 'validFromAfterDeclareIncident',
		value: () => null,
	},
	[ValidatorErrorCode.phoneNumberMandatory]: {
		label: 'phoneNumber.mandatory',
		value: () => null,
	},
};
