import { EventEmitter, Injectable, Output } from '@angular/core';
import { IncapacityToWork } from '../model/incapacity-to-work';
import { ApiDateFormat, dateToString, DialogService } from 'nc-utils';
import { IncapacityDialogComponent } from '../component/incapacity-table/incapacity-dialog/incapacity-dialog.component';
import { filter } from 'rxjs/operators';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import { Moment } from 'moment';
import {
	incapacityPercentageRange,
	isInvalidDifferenceBetweenIncapacities,
	isPreviousDateSet,
	isTakeWorkUpFullyGreaterThanValidTo,
	isValidFromGreaterThanValidTo,
} from '../validator/incapacity.validator';
import { TableData } from 'nc-datatable';

@Injectable({
	providedIn: 'root',
})
export class IncapacityService {
	@Output() fetchButtonVisibilityChange = new EventEmitter<boolean>();

	constructor(private dialogService: DialogService, private formBuilder: FormBuilder) {}

	/**
	 * Subscribes update table on form array value change
	 * @param formArray incapacity form array
	 * @param data$ incapacities behaviour subject that is linked to grid
	 */
	initialize(formArray: FormArray, data$: BehaviorSubject<TableData>): void {
		formArray.valueChanges.subscribe(() => {
			this.updateTable(data$, formArray);
		});
	}

	/**
	 * Initializes form array with existing incapacities
	 * @param formArray incapacity form array
	 * @param incapacities existing incapacities
	 * @param pkProInsuranceExitDate PK pro insurance exit date
	 */
	populateData(formArray: FormArray, incapacities: IncapacityToWork[], pkProInsuranceExitDate: Moment): void {
		formArray.clear();
		for (let i = 0; i < incapacities?.length; i++) {
			const incapacity = incapacities[i];
			const last = incapacities.at(-1);
			const isLast = incapacity.tableId === last.tableId;
			formArray.push(this.createExistingFormGroup(incapacity, isLast, i));

			if (i == 0 && pkProInsuranceExitDate != null) {
				const contractEndsDate = pkProInsuranceExitDate.startOf('day').toDate();
				const validFromDate = moment(incapacities[0].validFrom, 'DD-MM-YYYY').startOf('day').toDate();
				let isFetchButtonVisible = validFromDate && contractEndsDate !== null && validFromDate <= contractEndsDate;
				this.fetchButtonVisibilityChange.emit(isFetchButtonVisible);
			}
		}
	}

	/**
	 * Deletes incapacity
	 * @param model model from grid
	 * @param formArray incapacity form array
	 */
	delete(model: IncapacityToWork, formArray: FormArray): void {
		const index = formArray.getRawValue().findIndex((x) => x.tableId === model.tableId);
		if (model.id) {
			formArray.at(index).get('deleted').patchValue(true);
		} else {
			formArray.removeAt(index);
		}

		for (let i = 0; i < formArray.length; i++) {
			if (!formArray.at(i).get('deleted').value) {
				formArray.at(i).get('isFirst').patchValue(true);
				break;
			}
		}
	}

	/**
	 * Opens dialog for creation or edit of incapacity
	 * @param model incapacity model if exists or else null
	 * @param formArray incapacity form array
	 * @param isKleEnabled is kle enabled flag from company
	 * @param isAccident reason for incapacity to work
	 * @param isFetchButtonVisible should fetch button be visible
	 * @param contractStarts employee contract starts
	 * @param contractEnds employee contract ends
	 */
	openDialog(
		model: IncapacityToWork,
		formArray: FormArray,
		isKleEnabled: boolean,
		isAccident: boolean,
		isFetchButtonVisible: boolean,
		contractStarts: Moment,
		contractEnds: Moment
	): void {
		const formGroup = this.getFormGroup(formArray, model);
		const previousValidTo = this.getPreviousValidTo(model, formArray);
		const firstValidFrom = this.getFirstValidFrom(formArray);
		const isFirst = this.checkIfIsFirst(model, formArray);
		const isLast = formGroup.controls.isLast.value;
		const existsTakeWorkUpFullyAlready = this.checkExistsTakeWorkUpFully(model, formArray);

		formGroup.get('previousValidTo').patchValue(previousValidTo, { emitEvent: false });
		formGroup.get('firstValidFrom').patchValue(firstValidFrom, { emitEvent: false });
		formGroup.get('isFirst').patchValue(isFirst, { emitEvent: false });

		const dialogConfig = {
			width: '500px',
			data: {
				formGroup,
				isKleEnabled: isKleEnabled,
				isAccident: isAccident,
				isLast: isLast,
				existsTakeWorkUpFullyAlready: existsTakeWorkUpFullyAlready,
			},
		};

		const dialogRef = this.dialogService.open(IncapacityDialogComponent, dialogConfig);

		dialogRef
			.afterClosed()
			.pipe(filter((result) => result?.success))
			.subscribe(() => {
				if (!model) {
					formArray.push(formGroup);
					formGroup.markAsDirty();
				} else {
					const index = formArray.getRawValue().findIndex((x) => x.tableId === model.tableId);
					formArray.removeAt(index);
					formArray.insert(index, formGroup);
					formGroup.markAsDirty();

					const contractEndsDate = contractEnds.startOf('day').toDate();
					const validFromDate = moment(formGroup.controls.validFrom.value.toDate()).startOf('day').toDate();

					isFetchButtonVisible = isFirst && validFromDate && contractEndsDate !== null && validFromDate <= contractEndsDate;
				}

				this.fetchButtonVisibilityChange.emit(isFetchButtonVisible);
			});
	}

	/**
	 * Updates table based on data from form array
	 * @param data$ incapacities behaviour subject that is linked to grid
	 * @param formArray incapacity form array
	 */
	private updateTable = (data$: BehaviorSubject<TableData>, formArray: FormArray) => {
		const tableData = { records: [] };
		let previousValidTo = null;
		let firstValidFrom = this.getFirstValidFrom(formArray);

		for (let i = 0; i < formArray.length; i++) {
			const formGroup = formArray.at(i) as FormGroup;
			const rawValue = formGroup.getRawValue() as IncapacityToWork;
			if (!rawValue.deleted) {
				formGroup.get('previousValidTo').patchValue(previousValidTo, { emitEvent: false });
				formGroup.get('firstValidFrom').patchValue(firstValidFrom, { emitEvent: false });
				previousValidTo = rawValue.validTo;

				tableData.records.push(this.formatDataForTable(rawValue, formGroup.invalid));
			}
		}

		data$.next(tableData);
	};

	/**
	 * Gets form group from formArray if model is populated or creates new form group
	 * @param formArray incapacity form array
	 * @param model incapacity model
	 */
	private getFormGroup = (formArray: FormArray, model: IncapacityToWork): FormGroup => {
		let formGroup: FormGroup;

		if (model) {
			const index = formArray.getRawValue().findIndex((x) => x.tableId === model.tableId);
			const last = formArray.at(-1) as FormGroup;
			const isLast = index === last.value.tableId;
			const existing = formArray.at(index) as FormGroup;
			formGroup = this.createExistingFormGroup(existing.getRawValue(), isLast);
		} else {
			const tableId = formArray.length === 0 ? 0 : formArray.at(formArray.length - 1).get('tableId').value + 1;
			formGroup = this.createNewFormGroup(tableId);
		}

		return formGroup;
	};

	/**
	 * Gets valid to from previous incapacity
	 * @param model selected incapacity model
	 * @param formArray incapacity form array
	 */
	private getPreviousValidTo = (model: IncapacityToWork, formArray: FormArray): Moment => {
		const incapacities = formArray.getRawValue();

		const previousIndex = model
			? incapacities.filter((x) => x.deleted === false).findIndex((x) => x.tableId === model.tableId) - 1
			: incapacities.filter((x) => x.deleted === false).length - 1;

		return previousIndex >= 0 ? incapacities.filter((x) => x.deleted === false)[previousIndex].validTo : null;
	};
	private getFirstValidFrom(formArray: FormArray): Moment {
		const incapacities = formArray.getRawValue();
		const firstIncapacityIndex = incapacities.findIndex((item) => !item.deleted);
		return firstIncapacityIndex !== -1 ? incapacities[firstIncapacityIndex].validFrom : null;
	}
	/**
	 * Check if incapacity is first in array
	 * @param model selected incapacity model
	 * @param formArray incapacity form array
	 */
	private checkIfIsFirst = (model: IncapacityToWork, formArray: FormArray): boolean => {
		const incapacities = formArray.getRawValue();
		const IS_FIRST = 'isFirst';
		let isFirst = true;

		if (incapacities.filter((x) => x.deleted === false).length > 0) {
			isFirst = model && model[IS_FIRST];
		}

		return isFirst;
	};

	private checkExistsTakeWorkUpFully = (model: IncapacityToWork, formArray: FormArray): boolean => {
		const incapacities = formArray.getRawValue();
		return (
			(model && incapacities.filter((x) => x.deleted === false && x.takeWorkUpFullyDate !== null && x.tableId !== model.tableId).length > 0) ||
			(!model && incapacities.filter((x) => x.deleted === false && x.takeWorkUpFullyDate !== null).length > 0)
		);
	};

	/**
	 * Formats data for table
	 * @param model incapacity model
	 * @param isInvalid flag that indicates if model is invalid
	 */
	private formatDataForTable = (model: IncapacityToWork, isInvalid: boolean) => {
		return {
			...model,
			isInvalid,
			validFrom: dateToString(model.validFrom),
			validTo: dateToString(model.validTo),
			takeWorkUpFullyDate: null as Moment,
		};
	};

	/**
	 * Creates new form group for incapacity
	 * @param tableId table id value for new form group
	 */
	private createNewFormGroup = (tableId: number): FormGroup => {
		return this.formBuilder.group(
			{
				id: [null],
				isInvalid: [false],
				deleted: [false],
				tableId: [tableId],
				validFrom: [null, [Validators.required]],
				validTo: [null],
				percentage: [null, [Validators.required]],
				comment: [null],
				createdOn: [null],
				previousValidTo: [null],
				firstValidFrom: [null],
				isFirst: [null],
				isLast: [true],
				takeWorkUpFullyDate: [null as Moment],
			},
			{
				validators: [
					incapacityPercentageRange(),
					isValidFromGreaterThanValidTo(),
					isInvalidDifferenceBetweenIncapacities(),
					isPreviousDateSet(),
					isTakeWorkUpFullyGreaterThanValidTo(),
				],
			}
		);
	};

	/**
	 * Creates form group for existing incapacity
	 * @param incapacity incapacity model
	 * @param index if it is used for form array initialization index is used for table id
	 */
	private createExistingFormGroup = (incapacity: IncapacityToWork, isLast: boolean, index?: number) => {
		return this.formBuilder.group(
			{
				id: [incapacity.id],
				isInvalid: [false],
				deleted: [incapacity.deleted],
				tableId: [index ?? incapacity.tableId],
				validFrom: [moment(incapacity.validFrom, ApiDateFormat), [Validators.required]],
				validTo: [incapacity.validTo ? moment(incapacity.validTo, ApiDateFormat) : null],
				percentage: [incapacity.percentage.toString(), [Validators.required]],
				takeWorkUpFullyDate: [incapacity.takeWorkUpFullyDate ? moment(incapacity.takeWorkUpFullyDate, ApiDateFormat) : null],
				comment: [incapacity.comment],
				createdOn: [incapacity.createdOn],
				previousValidTo: [null],
				firstValidFrom: [null],
				isFirst: [index === 0],
				isLast: [isLast],
			},
			{
				validators: [
					incapacityPercentageRange(),
					isValidFromGreaterThanValidTo(),
					isInvalidDifferenceBetweenIncapacities(),
					isPreviousDateSet(),
					isTakeWorkUpFullyGreaterThanValidTo(),
				],
			}
		);
	};
}
