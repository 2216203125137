import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from 'nc-shared';
import { Observable, Subscription } from 'rxjs';
import { ColumnBuilder, DatatableColumn, TableData } from 'nc-datatable';
import { Store } from '@ngrx/store';
import { UserAdministrationPageAction, UserAdministrationSelect } from '../../state/action';
import { SettingsPageAction, SettingsSelect } from '../../../state/settings/action';
import { UserTypeEnumeration } from '../../../shared/enumeration/user-type.enumeration';
import { UserStatusEnumeration } from '../../../shared/enumeration/user-status.enumeration';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { UserSelect } from '../../../user/state/action';

@Component({
	templateUrl: './user-administration.component.html',
	styleUrls: ['./user-administration.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAdministrationComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
	users$: Observable<TableData>;
	usersColumn: DatatableColumn[];
	filterConfig: FilterConfig;
	userPreferences: {};
	loggedUser: string;
	loggedUserSubscription$: Subscription;

	NEO_ID_FILTER = 'neoIdFilter';
	ROLE_FILTER = 'roleFilter';
	NAME_FILTER = 'nameFilter';
	COMPANY_NAME_FILTER = 'companyNameFilter';
	USERNAME_FILTER = 'usernameFilter';
	EMAIL_FILTER = 'emailFilter';
	STATUS_FILTER = 'statusFilter';
	SORT_BY_COLUMN = 'sortByColumn';
	ORDER = 'order';

	constructor(private store: Store) {
		super();
		this.loggedUserSubscription$ = store.select(UserSelect.getUsername).subscribe((value) => {
			this.loggedUser = value;
		});
		this.store.dispatch(UserAdministrationPageAction.getAllUsers());

		const filteredUserTypeEnumeration = Object.fromEntries(
			Object.entries(UserTypeEnumeration).filter(([key, value]) => value !== UserTypeEnumeration.SUPERUSER)
		);

		this.usersColumn = [
			ColumnBuilder.createNumber('id', 'id'),
			ColumnBuilder.create('neoId', 'neoId', true),
			ColumnBuilder.createSelect('role', 'role', filteredUserTypeEnumeration),
			ColumnBuilder.create('name', 'name', true),
			ColumnBuilder.create('companyName', 'company', true),
			ColumnBuilder.create('username', 'username', true),
			ColumnBuilder.create('email', 'email', true),
			ColumnBuilder.createSelect('status', 'status', UserStatusEnumeration),
			ColumnBuilder.createAction('action', 'inactive', this.deactivate, { defaultIcon: 'check_circle_outline' }, this.isDeactivateVisible),
		];
	}

	ngOnInit(): void {
		this.users$ = this.store.select(UserAdministrationSelect.getUsers);
		this.store.select(SettingsSelect.getUserAdministrationUserPreferences(this.loggedUser)).subscribe((value) => {
			this.userPreferences = value;
		});

		this.filterConfig = {
			showFilter: true,
			showGlobalFilter: false,
			sortTableByColumn: this.userPreferences[this.SORT_BY_COLUMN],
			sortByAscOrDesc: this.userPreferences[this.ORDER],
			filterValues: {
				neoIdFilter: this.userPreferences[this.NEO_ID_FILTER],
				roleFilter: this.userPreferences[this.ROLE_FILTER],
				nameFilter: this.userPreferences[this.NAME_FILTER],
				companyNameFilter: this.userPreferences[this.COMPANY_NAME_FILTER],
				usernameFilter: this.userPreferences[this.USERNAME_FILTER],
				emailFilter: this.userPreferences[this.EMAIL_FILTER],
				statusFilter: this.userPreferences[this.STATUS_FILTER],
			},
		};
	}

	onRowClick = (rowData): void => {
		this.store.dispatch(SettingsPageAction.navigate({ url: `user-administration/${rowData.id}` }));
	};

	deactivate = (rowData): void => {
		if (rowData.active === 'true') {
			this.store.dispatch(UserAdministrationPageAction.deactivateUser({ id: rowData.id }));
		}
	};

	isDeactivateVisible = (rowData): boolean => {
		return rowData.active === 'true';
	};

	ngAfterViewInit(): void {
		this.loggedUserSubscription$.unsubscribe();
	}

	ngOnDestroy(): void {
		const filterObject = {
			neoIdFilter: this.filterConfig.columnFiltersFormGroup.get(this.NEO_ID_FILTER).value,
			roleFilter: this.filterConfig.columnFiltersFormGroup.get(this.ROLE_FILTER).value,
			nameFilter: this.filterConfig.columnFiltersFormGroup.get(this.NAME_FILTER).value,
			companyNameFilter: this.filterConfig.columnFiltersFormGroup.get(this.COMPANY_NAME_FILTER).value,
			usernameFilter: this.filterConfig.columnFiltersFormGroup.get(this.USERNAME_FILTER).value,
			emailFilter: this.filterConfig.columnFiltersFormGroup.get(this.EMAIL_FILTER).value,
			statusFilter: this.filterConfig.columnFiltersFormGroup.get(this.STATUS_FILTER).value,
			sortByColumn: this.filterConfig.sortTableByColumn,
			order: this.filterConfig.sortByAscOrDesc,
		};
		this.store.dispatch(
			SettingsPageAction.setUserAdministrationTableFilter({
				userAdministrationFilters: filterObject,
				loggedUser: this.loggedUser,
			})
		);
	}
}
