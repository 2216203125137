import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { CanDeactivateFormComponent, Option } from 'nc-shared';
import { FormBuilder, Validators } from '@angular/forms';
import { UserTypeEnumeration } from '../../../shared/enumeration/user-type.enumeration';
import { DialogConfigurationUtils, DialogService, EnumUtils } from 'nc-utils';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { MilestoneSettingsModelFactory } from '../../../milestone-settings/service/milestone-settings-model.factory';
import { ValidationService } from '../../../shared/service/validation.service';
import { ErrorSelect } from '../../../state/error/action';
import { UserAdministrationPageAction, UserAdministrationSelect } from '../../state/action';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { UserModel } from '../../model/user.model';
import { FlagEnumeration } from '../../../shared/enumeration/flag.enumeration';
import { LanguageEnumeration } from '../../../shared/enumeration/language.enumeration';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import {
	isNumber,
	mandatoryCompany,
	mobileNumberRequired,
	notEqualRepeatPassword,
	passwordRequired,
} from '../../validators/user-administration.validator';
import { SearchService } from '../../../shared/service/search.service';
import { UserModelFactory } from '../../service/user-model.factory';
import { CompaniesSelectService } from '../../service/companies-select.service';
import { PermissionEnumeration } from '../../../shared/enumeration/permission.enumeration';
import { TranslateService } from '@ngx-translate/core';
import { NcInformationDialogComponent } from 'nc-information-dialog';
import { ConfirmChangeTypeDialogComponent } from '../confirm-change-type/confirm-change-type-dialog.component';

@Component({
	templateUrl: './user-administration-form.component.html',
	styleUrls: ['./user-administration-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAdministrationFormComponent extends CanDeactivateFormComponent implements OnInit, OnDestroy, AfterViewChecked {
	userTypeEnum$: Observable<Option[]>;
	yesNo: Option[];
	language: Option[];
	user$: Observable<UserModel>;

	permissionHR = PermissionEnumeration.HR;
	permissionHRKle = PermissionEnumeration.HR_KLE;
	permissionBranchHR = PermissionEnumeration.BRANCH_HR;
	yesConstants = FlagEnumeration.yes;
	isSubmitted = false;
	isNew = true;

	companyOptions: Option[] = [];
	companyIds: number[];
	branches$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
	yesNo$: Observable<Option[]>;

	formGroup = this.formBuilder.group(
		{
			id: null as number,
			neoId: [null as number, isNumber()],
			language: 'de',
			type: [UserTypeEnumeration.HR.toUpperCase(), Validators.required],
			username: ['', Validators.required],
			firstName: ['', Validators.required],
			lastName: ['', Validators.required],
			email: ['', [Validators.required, Validators.email]],
			newPassword: '',
			repeatedPassword: '',
			mobileNumber: [''],
			hrKleOption: [''],
			isBranch: FlagEnumeration.no,
			hasTwoFactorAuthentication: FlagEnumeration.no,
			hasNoReplyEmail: FlagEnumeration.no,
			companies: [null as string[], []],
			companiesNoReplyEmail: [null as Array<string>, null],
			workplaceIds: [null as string[], []],
		},
		{
			validators: [mandatoryCompany(), notEqualRepeatPassword(), passwordRequired(), mobileNumberRequired()],
		}
	);

	constructor(
		private store: Store,
		private formBuilder: FormBuilder,
		private router: Router,
		private route: ActivatedRoute,
		private factory: MilestoneSettingsModelFactory,
		private validationService: ValidationService,
		private changeDetectorRef: ChangeDetectorRef,
		private searchService: SearchService,
		private modelFactory: UserModelFactory,
		private companiesSelectService: CompaniesSelectService,
		private translateService: TranslateService,
		private dialogService: DialogService
	) {
		super();

		this.route.params.subscribe((params) => {
			if (params.id) {
				this.isNew = false;
				this.store.dispatch(UserAdministrationPageAction.getUser({ id: params.id }));
			}
		});

		this.user$ = this.store.select(UserAdministrationSelect.getUser);
		this.store
			.select(ErrorSelect.getErrors)
			.subscribe((errors) => this.validationService.displayErrors(errors, this.formGroup, this.changeDetectorRef));
	}

	ngOnInit(): void {
		this.userTypeEnum$ = EnumUtils.toObservableItems(UserTypeEnumeration).pipe(
			map((options: Option[]) => options.filter((option) => option.text !== UserTypeEnumeration.SUPERUSER))
		);
		this.yesNo = EnumUtils.toItems(FlagEnumeration);
		this.yesNo$ = EnumUtils.toObservableItems(FlagEnumeration);
		this.language = EnumUtils.toItems(LanguageEnumeration);

		this.store.select(UserAdministrationSelect.getGeneratedPassword).subscribe((password) => {
			this.formGroup.controls.newPassword.patchValue(password);
			this.formGroup.controls.repeatedPassword.patchValue(password);
		});

		this.user$
			.pipe(
				filter((user) => user !== null && user.id !== null),
				takeUntil(this.destroy$)
			)
			.subscribe((user) => {
				this.displayUserAdministration(user);
				if (this.isBranchesVisible()) {
					this.fillBranchesSelect();
				}
			});

		this.hasNoReplyValueChanges();
		this.hrKleOptionValueChanges();
	}

	displayUserAdministration = (user: UserModel): void => {
		this.formGroup.reset();
		this.formGroup.patchValue({
			...user,
			newPassword: user.password,
			repeatedPassword: user.repeatedPassword,
			hasTwoFactorAuthentication: user.hasTwoFactorAuthentication ? FlagEnumeration.yes : FlagEnumeration.no,
			companies: user.companyIds,
			companiesNoReplyEmail: user.companyNoReplyEmailIds.length !== 0 ? user.companyNoReplyEmailIds : user.companyIds,
			hasNoReplyEmail: user.hasNoReplyEmail ? FlagEnumeration.yes : FlagEnumeration.no,
			workplaceIds: user.workplaceIds,
		});
	};

	dispatchSubmitAction = (): void => {
		this.isSubmitted = true;
		const data = this.formGroup.getRawValue();
		const user = this.modelFactory.create(data);
		this.store.dispatch(UserAdministrationPageAction.saveUser({ user }));
	};

	searchCompanies = (term) => of(term).pipe(switchMap((query) => this.searchService.getCompanies(query)));

	fetchCompanies = (value) =>
		of(value).pipe(
			filter((ids) => ids != null && ids.length !== 0),
			switchMap((ids) => this.companiesSelectService.getCompanyOptions(ids.join(',')))
		);

	generatePassword = (): void => {
		this.store.dispatch(UserAdministrationPageAction.generatePassword());
	};

	ngOnDestroy(): void {
		this.store.dispatch(UserAdministrationPageAction.clearUser());
	}

	ngAfterViewChecked() {
		if (!this.formGroup.value.id) {
			if (!this.formGroup.controls.username.touched) {
				this.formGroup.controls.username.reset('', { emitEvent: false });
			}
			if (!this.formGroup.controls.newPassword.touched) {
				this.formGroup.controls.newPassword.reset('', { emitEvent: false });
			}
		}
	}

	showCompanyError(): boolean {
		return this.formGroup.errors?.requiredCompany && (this.isSubmitted || this.formGroup.controls.companies.touched);
	}

	focusoutCompanies(): void {
		this.formGroup.controls.companies.markAsTouched();
		if (this.isBranchesVisible()) {
			this.fillBranchesSelect();
		}
	}

	hasNoReplyValueChanges(): void {
		this.formGroup.controls.hasNoReplyEmail.valueChanges.subscribe((x) => {
			if (x === FlagEnumeration.yes && !this.formGroup.value.companiesNoReplyEmail?.length) {
				this.formGroup.controls.companiesNoReplyEmail.patchValue(this.formGroup.controls.companies.value);
			} else if (x === FlagEnumeration.no) {
				this.formGroup.controls.companiesNoReplyEmail.patchValue(null);
			}
		});
	}

	onPaste(event: ClipboardEvent) {
		event.preventDefault();
		const selectedCompanies = this.formGroup.value.companies || [];
		this.store
			.select(UserAdministrationSelect.getCopiedCompanies)
			.pipe(takeUntil(this.destroy$))
			.subscribe((companies) => {
				this.formGroup.controls.companies.patchValue([...selectedCompanies, ...companies]);
			});
	}

	onCopy(event: ClipboardEvent) {
		event.preventDefault();
		this.copyCompanies();
	}

	copyCompanies() {
		this.store.dispatch(UserAdministrationPageAction.copyCompanies({ copiedCompanies: this.formGroup.value.companies }));
		this.dialogService.open(NcInformationDialogComponent, DialogConfigurationUtils.info('allCompaniesAreCopied', 'info'));
	}

	fillBranchesSelect() {
		const companyIds = this.formGroup.controls.companies.value.map(function (item) {
			return parseInt(item, 10);
		});
		let currentBranches = this.formGroup.controls.workplaceIds.value ?? [];
		this.companiesSelectService.getBranches(companyIds).subscribe((data) => {
			this.branches$.next(data);
			const ids = data.map((x) => x.value);
			if (currentBranches?.length) {
				this.formGroup.controls.workplaceIds.reset();
				currentBranches = currentBranches.filter((item) => ids.includes(item));
				this.formGroup.controls.workplaceIds.patchValue(currentBranches);
			}
		});
	}

	isBranchesVisible() {
		if (this.formGroup.controls.companies.value && this.formGroup.controls.type.value === this.permissionBranchHR) {
			return true;
		} else {
			this.formGroup.controls.workplaceIds.setValue([]);
			return false;
		}
	}

	isHR(): boolean {
		return this.formGroup.value.type === this.permissionHR;
	}

	private hrKleOptionValueChanges(): void {
		this.formGroup.controls.hrKleOption.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
			if (value && value === FlagEnumeration.yes) {
				this.confirmChangeUserType();
			}
		});
	}

	private confirmChangeUserType(): void {
		const dialogRef = this.dialogService.open(ConfirmChangeTypeDialogComponent);

		dialogRef.afterClosed().subscribe((confirmed) => {
			if (!confirmed) {
				this.revertHrKleOption();
			}
		});
	}

	private revertHrKleOption() {
		this.formGroup.controls.hrKleOption.patchValue(null);
	}
}
