import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Option } from 'nc-shared';
import { CompaniesSelectModel } from '../model/companies-select.model';
import { ContractTypeEnumeration } from '../../user/model/contract-type.enumeration';
import { BranchesSelectModel } from '../model/branches-select.model';

@Injectable({
	providedIn: 'root',
})
export class CompaniesSelectFactory {
	selectDataModel: Option[];

	constructor(private translateService: TranslateService) {}

	/**
	 * With single id
	 */
	createCompanySelectModel(companies: CompaniesSelectModel[]): Option[] {
		this.selectDataModel = [];

		for (const company of companies) {
			this.selectDataModel.push({
				value: company.id,
				text: this.convertModelText(
					company.name ? company.name : '',
					company.neoId ? company.neoId : '',
					company.easyTempId ? company.easyTempId : '',
					company.contractType ? company.contractType : '',
					''
				),
			});
		}
		return this.selectDataModel;
	}

	createBranchesSelectModel(branches: BranchesSelectModel[]): Option[] {
		this.selectDataModel = [];

		for (const branch of branches) {
			this.selectDataModel.push({
				value: branch.id,
				text: this.convertBranchModel(branch.companyName, branch.fullName),
			});
		}
		return this.selectDataModel;
	}

	createCompanySelectModelWithIdsArray(companies: any): Option[] {
		this.selectDataModel = [];

		for (const company of companies) {
			if (company.name !== 'showAll') {
				this.selectDataModel.push({
					value: company.ids.toString(),
					text: this.convertModelText(
						company.name ? company.name : '',
						company.neoId ? company.neoId : '',
						company.easyTempId ? company.easyTempId : '',
						company.contractType ? company.contractType : '',
						company.companyGroupName ? company.companyGroupName : ''
					),
				});
			}
		}
		return this.selectDataModel;
	}

	convertModelText(name: string, neoId: number | string, easyTempId: number | string, contractType: string, groupName: string): string {
		const nameString = name ? ' ' + name : '';
		const neoIdString = neoId ? '[NeoId: ' + neoId.toString() + ']' : '';
		const easyTempIdString = easyTempId ? '[EasyTempId: ' + easyTempId.toString() + ']' : '';
		const contractTypeString = ' - ' + this.getContractTypeValue(contractType);
		let returnValue;

		if (groupName === '' && name !== '') {
			returnValue = nameString + ' ' + neoIdString + ' ' + easyTempIdString + ' ' + contractTypeString;
		} else {
			returnValue = groupName;
		}

		return returnValue;
	}

	convertBranchModel(companyName: string, name: string): string {
		return companyName + '-' + name;
	}

	getContractTypeValue(contractType: string): string {
		let returnValue;

		switch (contractType) {
			case 'PERMANENT':
				returnValue = this.translateService.instant(ContractTypeEnumeration.PERMANENT);
				break;
			case 'TEMPORARY':
				returnValue = this.translateService.instant(ContractTypeEnumeration.TEMPORARY);
				break;
			default:
				returnValue =
					this.translateService.instant(ContractTypeEnumeration.PERMANENT) +
					' & ' +
					this.translateService.instant(ContractTypeEnumeration.TEMPORARY);
				break;
		}

		return returnValue;
	}
}
