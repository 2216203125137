import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { BaseComponent, Option } from 'nc-shared';
import { Store } from '@ngrx/store';
import { AbsencePageAction, AbsenceSelect } from '../../../absence/state/action';
import { Observable } from 'rxjs';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { IncapacityService } from '../../../absence/service/incapacity.service';
import { Absence } from '../../../absence/model/absence';
import { ErrorSelect } from '../../../state/error/action';
import { ValidationService } from '../../../shared/service/validation.service';
import { ActivatedRoute } from '@angular/router';
import { KleFormGroupEnum } from '../../model/enums/kle-form-group.enum';
import { FlagEnumeration } from '../../../shared/enumeration/flag.enumeration';
import { EnumUtils } from 'nc-utils';

@Component({
	selector: 'ea-kle-incapacity-to-work',
	templateUrl: './kle-incapacity-to-work.component.html',
	styleUrls: ['kle-incapacity-to-work.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KleIncapacityToWorkComponent extends BaseComponent implements OnInit {
	@Input() formGroup: FormGroup;
	incapacitiesGroup: FormGroup;
	absence$: Observable<Absence>;
	incapacities: FormArray;
	absenceId: number;
	yesNoEnum: Option[];

	constructor(
		private store: Store,
		private incapacityService: IncapacityService,
		private formBuilder: FormBuilder,
		private changeDetectorRef: ChangeDetectorRef,
		private validationService: ValidationService,
		private route: ActivatedRoute
	) {
		super();
		this.incapacitiesGroup = this.formBuilder.group({
			withoutIncapacity: [FlagEnumeration.no],
			incapacities: this.formBuilder.array([]),
		});

		this.yesNoEnum = EnumUtils.toItems(FlagEnumeration);
		this.absence$ = this.store.select(AbsenceSelect.getAbsence);
		this.store
			.select(ErrorSelect.getErrors)
			.subscribe((errors) => this.validationService.displayErrors(errors, this.incapacitiesGroup, this.changeDetectorRef));
	}

	ngOnInit(): void {
		this.formGroup.addControl(KleFormGroupEnum.INCAPACITIES_TO_WORK, this.incapacitiesGroup);

		this.incapacities = this.incapacitiesGroup.get('incapacities') as FormArray;
		this.route.params.subscribe((params) => {
			this.store.dispatch(AbsencePageAction.fetchAbsence({ code: params.code }));
		});

		this.absence$.pipe(filter((absence) => absence.id > 0)).subscribe((absence) => {
			this.absenceId = absence.id;
			this.incapacityService.populateData(this.incapacities, absence.incapacities, null);
		});
	}
}
