import { Injectable } from '@angular/core';
import { FlagEnumeration, toBoolean } from '../../shared/enumeration/flag.enumeration';
import { StringsUtils } from 'nc-utils';
import { PermissionEnumeration } from '../../shared/enumeration/permission.enumeration';

@Injectable({
	providedIn: 'root',
})
export class UserModelFactory {
	create(user: any): any {
		return {
			...user,
			type: user.hrKleOption === FlagEnumeration.yes ? PermissionEnumeration.HR_KLE : user.type,
			isBranch: toBoolean(user.isBranch),
			hasTwoFactorAuthentication: toBoolean(user.hasTwoFactorAuthentication),
			hasNoReplyEmail: toBoolean(user.hasNoReplyEmail),
			neoId: user.neoId ? user.neoId : 0,
			password: StringsUtils.isEmpty(user.newPassword) ? '' : user.newPassword,
			repeatedPassword: StringsUtils.isEmpty(user.repeatedPassword) ? '' : user.repeatedPassword,
			companies: user.companies
				? user.companies.map((x) => {
						return { value: x };
				  })
				: [],
			companiesNoReplyEmail: user.companiesNoReplyEmail
				? user.companiesNoReplyEmail.map((x) => {
						return { value: x };
				  })
				: [],
		};
	}
}
