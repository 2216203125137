export enum UserTypeEnumeration {
	HR = 'hr',
	ADMIN = 'admin',
	CASE_MANAGER = 'caseManager',
	LD = 'leistungsdienst',
	ZENDESK = 'zendesk',
	SUPERUSER = 'superuser',
	BRANCH_HR = 'branchHr',
	HR_KLE = 'hrKle',
}
