import { createReducer, on } from '@ngrx/store';
import { MAINTENANCE_MESSAGE_MANAGEMENT_INITIAL_STATE, MaintenanceMessageState } from './maintenance-message.state';
import { MaintenanceMessageApiAction } from './action';

export const MaintenanceMessageReducer = createReducer<MaintenanceMessageState>(
	MAINTENANCE_MESSAGE_MANAGEMENT_INITIAL_STATE,
	on(MaintenanceMessageApiAction.getMaintenanceMessageSuccess, (state, action): MaintenanceMessageState => {
		return {
			...state,
			message: action.message,
		};
	})
);
