import { CanDeactivateFormComponent } from 'nc-shared';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { Store } from '@ngrx/store';
import { MaintenanceMessage } from '../model/maintenance-message';
import { MaintenanceMessagePageAction, MaintenanceMessageSelect } from '../state/action';
import { filter, takeUntil } from 'rxjs/operators';
import { ApiDateTimeFormat, dateTimeToString, parseDate } from 'nc-utils';
import { FormValidation } from '../../shared/form-validation';

@Component({
	templateUrl: './maintenance-message.component.html',
	styleUrls: ['./maintenance-message.component.scss'],
})
export class MaintenanceMessageComponent extends CanDeactivateFormComponent implements OnInit {
	formGroup: FormGroup;
	filterConfig: FilterConfig;

	constructor(private store: Store, private formBuilder: FormBuilder) {
		super();
	}

	ngOnInit(): void {
		this.formGroup = this.formBuilder.group(
			{
				de: ['', [Validators.required]],
				en: ['', [Validators.required]],
				fr: ['', [Validators.required]],
				it: ['', [Validators.required]],
				dateFrom: ['', [Validators.required]],
				dateTo: ['', [Validators.required]],
			},
			{ validators: [this.untilDateValidator()] }
		);

		this.getMessage();
	}

	getMessage(): void {
		this.store.dispatch(MaintenanceMessagePageAction.getMaintenanceMessage());
		this.store
			.select(MaintenanceMessageSelect.getMessage)
			.pipe(
				filter((message) => message != null),
				takeUntil(this.destroy$)
			)
			.subscribe((message) => this.displayMessage(message));
	}

	displayMessage(message: MaintenanceMessage): void {
		this.formGroup.reset();
		this.formGroup.patchValue({
			...message,
			dateFrom: parseDate(message.dateFrom, ApiDateTimeFormat),
			dateTo: parseDate(message.dateTo, ApiDateTimeFormat),
		});
	}

	onSubmit = () => {
		const data = this.createSubmitData();
		this.store.dispatch(MaintenanceMessagePageAction.saveMaintenanceMessage({ message: data }));
		this.formGroup.markAsPristine();
	};

	private createSubmitData(): MaintenanceMessage {
		return {
			...this.formGroup.getRawValue(),
			dateFrom: dateTimeToString(this.formGroup.value.dateFrom),
			dateTo: dateTimeToString(this.formGroup.value.dateTo),
		};
	}

	private untilDateValidator = (): ValidatorFn => {
		return (control: AbstractControl): { [key: string]: boolean } | null => {
			const dateFrom = control.get('dateFrom').value;
			const dateToControl = control.get('dateTo');
			const dateTo = dateToControl.value;

			if (dateFrom && dateTo && dateFrom.isSameOrAfter(dateTo)) {
				FormValidation.addError(dateToControl, { maintenanceMessageDates: true });
			} else {
				FormValidation.removeError(dateToControl, { maintenanceMessageDates: true });
			}
			return null;
		};
	};
}
