import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ColumnBuilder, DatatableColumn, TableData } from 'nc-datatable';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'nc-shared';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { LabelManagementPageAction, LabelManagementSelect } from '../state/action';
import { FilterConfig } from 'nc-datatable/lib/model/filter/filter-config';
import { UserSelect } from '../../user/state/action';
import { SettingsPageAction, SettingsSelect } from '../../state/settings/action';

@Component({
	templateUrl: './label-management.component.html',
	styleUrls: ['./label-management.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelManagementComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
	formGroup: FormGroup;
	filterConfig: FilterConfig;
	globalFormGroup: FormGroup;
	labels$: Observable<TableData>;
	labelColumns: DatatableColumn[];
	userPreferences: {};
	loggedUser: string;
	loggedUserSubscription$: Subscription;

	GLOBAL_FILTER = 'globalFilter';
	SORT_BY_COLUMN = 'sortByColumn';
	ORDER = 'order';

	constructor(private store: Store, formBuilder: FormBuilder, private router: Router) {
		super();
		this.loggedUserSubscription$ = store.select(UserSelect.getUsername).subscribe((value) => {
			this.loggedUser = value;
		});

		this.formGroup = formBuilder.group({});
		this.store.dispatch(LabelManagementPageAction.getLabels());
	}

	ngOnInit(): void {
		this.store.select(SettingsSelect.getLabelManagementUserPreferences(this.loggedUser)).subscribe((value) => {
			this.userPreferences = value;
		});
		this.labelColumns = [
			ColumnBuilder.create('code', 'labelCode'),
			ColumnBuilder.create('de', 'german'),
			ColumnBuilder.create('en', 'english'),
			ColumnBuilder.create('fr', 'french'),
			ColumnBuilder.create('it', 'italian'),
		];
		this.labels$ = this.store.select(LabelManagementSelect.getLabels);
		this.filterConfig = {
			showFilter: false,
			showGlobalFilter: true,
			sortTableByColumn: this.userPreferences[this.SORT_BY_COLUMN],
			sortByAscOrDesc: this.userPreferences[this.ORDER],
			filterValues: {
				globalFilter: this.userPreferences[this.GLOBAL_FILTER],
			},
		};
	}

	onRowClick = (label): void => {
		this.router.navigateByUrl('/label/management/' + label.code);
	};

	ngAfterViewInit(): void {
		this.loggedUserSubscription$.unsubscribe();
	}

	ngOnDestroy(): void {
		const filterObject = {
			globalFilter: this.filterConfig.globalFilterFormGroup.get(this.GLOBAL_FILTER).value,
			sortByColumn: this.filterConfig.sortTableByColumn,
			order: this.filterConfig.sortByAscOrDesc,
		};
		this.store.dispatch(SettingsPageAction.setLabelManagementTableFilter({ labelManagementFilters: filterObject, loggedUser: this.loggedUser }));
	}
}
