export enum ValidatorErrorCode {
	required = 'required',
	maxlength = 'maxlength',
	minlength = 'minlength',
	email = 'email',
	pattern = 'pattern',
	invalidPassword = 'invalidPassword',
	numberValueLength = 'numberValueLength',
	mismatch = 'mismatch',
	matDatepickerParse = 'matDatepickerParse',
	customEmail = 'customEmail',
	maxFileSize = 'maxFileSize',
	requiredFile = 'requiredFile',
	atLeastOne = 'atLeastOne',
	atLeastOneSelected = 'atLeastOneSelected',
	invalidContractNumberOrName = 'invalidContractNumberOrName',
	invalidFormatPhone = 'invalidFormatPhone',
	invalidIncapacityPercentage = 'invalidIncapacityPercentage',
	invalidValidFromValidToRange = 'invalidValidFromValidToRange',
	invalidIncapacityDatesForContractPeriodValidFrom = 'invalidIncapacityDatesForContractPeriodValidFrom',
	invalidIncapacityDatesForContractPeriodValidTo = 'invalidIncapacityDatesForContractPeriodValidTo',
	passwordsNotEqual = 'passwordsNotEqual',
	passwordsAreEqual = 'passwordsAreEqual',
	uniqueName = 'uniqueName',
	totalMaxFilesSize = 'totalMaxFilesSize',
	invalidDifferenceBetweenIncapacities = 'invalidDifferenceBetweenIncapacities',
	incapacityRequired = 'incapacityRequired',
	mandatoryAbsenceType = 'mandatoryAbsenceType',
	dayMustBeGreaterThanZero = 'dayMustBeGreaterThanZero',
	noteAtLeastOneLanguage = 'noteAtLeastOneLanguage',
	titleAtLeastOneLanguage = 'titleAtLeastOneLanguage',
	notEqualRepeatPassword = 'notEqualRepeatPassword',
	absenceAnnexDocumentsCommentRequired = 'absenceAnnexDocumentsCommentRequired',
	invalidPreviousDate = 'invalidPreviousDate',
	caseManagementDetailsStartedOnDateRequired = 'caseManagementDetailsStartedOnDateRequired',
	caseManagementDetailsEndedOnDateRequired = 'caseManagementDetailsEndedOnDateRequired',
	caseManagementDetailsConclusionRequired = 'caseManagementDetailsConclusionRequired',
	invalidTakeWorkUpFullyAfterValidTo = 'invalidTakeWorkUpFullyAfterValidTo',
	dateOfDeathError = 'dateOfDeathError',
	lastWorkingDayError = 'lastWorkingDayError',
	injuriesMandatory = 'injuriesMandatory',
	relapseDateError = 'relapseDateError',
	accidentDateInFuture = 'accidentDateInFuture',
	relapseDateInFuture = 'relapseDateInFuture',
	maintenanceMessageDates = 'maintenanceMessageDates',
	categoryMandatoryError = 'categoryMandatoryError',
	employmentContractMandatoryFields = 'employmentContractMandatoryFields',
	numberForNeoID = 'numberForNeoID',
	validFromAfterDeclareIncident = 'validFromAfterDeclareIncident',
	phoneNumberMandatory = 'phoneNumber.mandatory',
}
